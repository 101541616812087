
import { Component, Vue } from "vue-property-decorator";
import UploadedFile from "@/entities/UploadedFile";
import BasePage from "@/components/Shared/Page/Page.vue";
import Tiers from "../Tiers/Tiers.vue";

/**
 * Pricing page
 */
@Component({
  name: "PricingPage",
  components: {
    BasePage,
    Tiers
  },
})
export default class Page extends Vue {
  private finish(uploadedFile: UploadedFile) {
    /**
     * Emit the uploaded file
     *
     * @type {UploadedFile}
     */
    this.$emit("finish", uploadedFile);
  }
}
